window.touchEvent = 'ontouchstart' in window ? 'touchstart' : 'click';
const qs = (s) => document.querySelector(s);
const qa = (s, f) => document.querySelectorAll(s).forEach(f);

let opened = false;
qs('[data-action="open-menu"]').addEventListener(touchEvent, () => {
  qs('body').classList[opened ? 'remove': 'add']('menu-opened');
  opened = !opened;
});

qa('[data-action="open-menu-item"]', function(e) {
  e.addEventListener(touchEvent, (ev) => {
    if (touchEvent === 'touchstart') ev.preventDefault();
    e.parentElement.classList.toggle('opened');
  });
});

const offset = 100;
let prev = 0;
window.addEventListener('scroll', () => {
  if (window.pageYOffset < prev) {
    qs('body').classList.remove('compact-top');
  } else {
    if (window.pageYOffset > offset) {
      qs('body').classList.add('compact-top');
    }
  }
  prev = window.pageYOffset;
});

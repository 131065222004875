import sizes from "../scss/_settings/_settings.scss";

window.sizes = {};
Object.keys(sizes).map(function(key){
    window.sizes[key] = parseInt(sizes[key]);
});

document.addEventListener('touchmove', function (event) {
    if (event.scale !== 1) { event.preventDefault(); }
}, false);


// Important parts
require('./parts/mobile-menu.js');

require('../scss/mobile.scss');
require('../fonts/Avenir/face.css');
require('../fonts/Montserrat/face.css');
